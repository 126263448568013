<template>
  <default-layout>
    <v-container id="project-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <detail :detail="detail" :permissions="projectPermissions"></detail>
      <v-row>
        <v-col cols="6">
          <unit-type-list
            :items="unitTypes"
            :meta="unitTypeMeta"
            :permissions="unitTypePermissions"
            :project-uuid="detail.uuid"
            @changePage="changePageUnitType"
          ></unit-type-list>
        </v-col>
        <v-col cols="6">
          <unit-category-list
            :items="categories"
            :meta="categoryMeta"
            :permissions="categoryPermissions"
            :project-uuid="detail.uuid"
            @changePage="changePageUnitCategory"
          ></unit-category-list>
        </v-col>
      </v-row>
      <unit-list
        :items="units"
        :meta="unitMeta"
        :permissions="unitPermissions"
        :project-uuid="detail.uuid"
        @changePage="changePageUnit"
      ></unit-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const Detail = () => import('@/components/project-management/project/detail');
const UnitTypeList = () => import('@/components/project-management/unit-type/list');
const UnitCategoryList = () => import('@/components/project-management/unit-category/list');
const UnitList = () => import('@/components/project-management/unit/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    Detail,
    UnitTypeList,
    UnitCategoryList,
    UnitList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      projectPermissions: (state) => state.project.permissions,
      detail: (state) => state.project.detail,
      unitTypePermissions: (state) => state.unitType.permissions,
      unitTypes: (state) => state.unitType.unitTypes,
      unitTypeMeta: (state) => state.unitType.meta,
      categoryPermissions: (state) => state.unitCategory.permissions,
      categories: (state) => state.unitCategory.categories,
      categoryMeta: (state) => state.unitCategory.meta,
      unitPermissions: (state) => state.unit.permissions,
      units: (state) => state.unit.units,
      unitMeta: (state) => state.unit.meta,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let promises = [];
      let query = this.$route.query;
      query.project_uuid = this.$route.params.uuid;

      this.$store.commit('project/RESET_DEFAULT');
      this.$store.commit('unitType/RESET_DEFAULT');
      this.$store.commit('unitCategory/RESET_DEFAULT');
      this.$store.commit('unit/RESET_DEFAULT');
      promises.push(this.$store.dispatch('project/getDetailInitData', this.$route.params.uuid));
      promises.push(this.$store.dispatch('unit/getInitData', query));

      delete query.page;
      promises.push(this.$store.dispatch('unitType/getInitData', query));
      promises.push(this.$store.dispatch('unitCategory/getInitData', query));

      const response = await Promise.all(promises);
      console.log('DETAIL PROJECT: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async changePageUnitType(page) {
      try {
        this.isLoading = true;
        let query = this.$route.query;
        query.project_uuid = this.$route.params.uuid;
        query.page = page;
        const response = await this.$store.dispatch('unitType/getInitData', query);
        console.log('GET UNIT TYPE DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    async changePageUnitCategory(page) {
      try {
        this.isLoading = true;
        let query = this.$route.query;
        query.project_uuid = this.$route.params.uuid;
        query.page = page;
        const response = await this.$store.dispatch('unitCategory/getInitData', query);
        console.log('GET UNIT CATEGORY DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    async changePageUnit(page) {
      try {
        this.isLoading = true;
        let query = this.$route.query;
        query.project_uuid = this.$route.params.uuid;
        query.page = page;
        const response = await this.$store.dispatch('unit/getInitData', query);
        console.log('GET UNIT DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
